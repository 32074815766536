.recipe-holder__recipe {
    margin-bottom:10px;
}

.recipe-holder__recipe:last-child {
    margin-bottom:0;
}

.recipe-holder__recipe .list{
    padding-bottom: 0;
}

.recipe-holder__recipe .list__tile{
     height:auto;
}

.recipe-holder  .card__actions{
    padding-left:8px;
}

.sidebar > .card {
    margin-bottom: 10px;
}

.sidebar > .card:last-child {
    margin-bottom:0;
}

.repostatus {
    font-size: .8em;
}

body .application a {
    color: #0d5aa7;
    text-decoration: none;
}